import { ServiceManager, ServiceBusController, ServiceBusControllerBody } from '@estee/elc-service';
import { events, on, commandProvider, serviceCommands } from '@estee/elc-service-bus';

const commands = [
    [serviceCommands.ACCOUNT_OVERLAY_SHOW, 'showOverlay'],
    [serviceCommands.ACCOUNT_OVERLAY_HIDE, 'hideOverlay']
];

export class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        commands.forEach(ServiceManager.setDecoratorOnFunction(commandProvider));
    };
}

export const serviceBusBaseClass = new ServiceBus() as ServiceBusControllerBody;
