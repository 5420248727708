import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';
import { serviceName, version } from '~setup/packageInfo';

export class AccountService extends BaseService {
    constructor(serviceInitializer: ServiceInitializer) {
        super(serviceName, version, serviceInitializer);
    }
}

export default {
    accountOverlay: 'ACCOUNT_OVERLAY',
    accountRepository: 'ACCOUNT_REPOSITORY',
    accountService: 'ACCOUNT_SERVICE',
    accountDashboardViewController: 'ACCOUNT_DASHBOARD_VIEW_CONTROLLER',
    accountOrdersViewController: 'ACCOUNT_ORDERS_VIEW_CONTROLLER',
    accountSidebarViewController: 'ACCOUNT_SIDEBAR_VIEW_CONTROLLER',
    authenticatedRepository: 'AUTHENTICATED_REPOSITORY',
    config: 'CONFIG',
    configRepository: 'CONFIG_REPOSITORY',
    httpTransport: 'HTTP_TRANSPORT',
    serviceBusController: 'SERVICE_BUS_CONTROLLER'
};
