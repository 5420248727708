import { ServiceManager } from '@estee/elc-service';
import { ACCOUNT_DASHBOARD, ACCOUNT_ORDERS, ACCOUNT_SIDEBAR } from '@estee/elc-service-view-names';
import { getModuleFederatedName, setupServiceInfo } from '@estee/elc-universal-utils';

import serviceNames, { AccountService } from '~setup/AccountService';
import { serviceBusBaseClass } from '~setup/ServiceBus';

setupServiceInfo(__serviceInfo__);
const { name, version } = __serviceInfo__;

AccountService.setViews(name, getModuleFederatedName(name), version, [
    ACCOUNT_SIDEBAR,
    ACCOUNT_DASHBOARD,
    ACCOUNT_ORDERS
]);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.accountService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBusBaseClass
});
